import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import thumbnailEvent from "../../static/images/feature-event.jpg"
import thumbnailBoard from "../../static/images/feature-board.jpg"
import thumbnailNews from "../../static/images/feature-news.jpg"
import thumbnailTeams from "../../static/images/feature-team.jpg"
import ContactForm from "../components/form"

const IndexPage = () => (
  <Layout>
    <SEO title="Proteze dentare mobile Galati" />

    <div className={"page-header home"}>
      <div className="container">
        <h1>Proteze Mobile Dentare</h1>
        <p>
          Proteze făcute cu pasiune și suflet! Satisfacția dumneavoastră, bucuria
          noastră!
        </p>
        <div className="embed-container">
          <iframe
            title="Proteze dentare mobile"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/slR_YaGzVPw?controls=0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>

    <div className={"container"}>
      <div className={"features"}>
        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Event"} src={thumbnailEvent} />
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <p>
                  Tratamentul cu proteze dentare mobile este o provocare
                  deoarece trebuie satisfăcute toate funcțiile pierdute:
                  estetica, masticația, fonația și nu în ultimul rând
                  recăpătarea stimei de sine.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"feature__content"}>
                <p>
                  Înainte de începerea tratamentului medicii se confruntă
                  deseori cu refuzul pacientului pentru un astfel de tratament,
                  dar trebuie înțeles faptul că tratamentul cu proteze nu este
                  neapărat definitiv ci poate fi o etapă premergătoare de
                  echilibrare neuro-musculară înainte de inserția implanturilor
                  sau miniimplanturilor.
                </p>
              </div>
            </div>

            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Board"} src={thumbnailBoard} />
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"News"} src={thumbnailNews} />
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <p>
                  Ne-am perfecționat în tratamentul cu proteze dentare mobile cu
                  Domnul Dr. Octavian Jecherean, trainer la firma elvețiană
                  Candulor, care ne și pune la dispoziție materiale de ultimă
                  generație, realizând o estetică deosebită a protezei
                  comparativ cu protezele clasice.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"feature__content"}>
                <p>
                  Temerile pacienților ce vor fi purtători de proteze mobile
                  dentare sunt legate de stabilitatea și estetica protezei. Prin
                  măsurătorile craniometrice, amprentările multiple și cât mai
                  fidele și nu în ultimul rând prin alegerea dinților și
                  montarea lor în funcție de gradul de atrofie al osului, se
                  ajunge la o stabilitate superioară.
                </p>
              </div>
            </div>

            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Team"} src={thumbnailTeams} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={"call-to-action"}>
      <div className={"container"}>
        <div className={"call-to-action__content"}>
          <h2>Contact</h2>
          <p>
            <strong>Adresa:</strong> Țiglina 2, Str. Brăilei, Nr. 136 C, Etaj 1,
            Cabinet 10, Policlinica Medicotest (in spatele Leagănului de copii).
          </p>
          <p>
            <strong>Telefon:</strong>{" "}
            <a href="tel:0755 341 659">0755 341 659</a>
          </p>
          <p>
            <strong>Program:</strong> Luni - Vineri 08:00 - 20:00
          </p>
          <p>
            <br />
            <strong>Formular contact</strong>
          </p>
          <ContactForm />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
