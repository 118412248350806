import React from "react"
import { Link } from "gatsby"

const ContactForm = () => (
  <>
    <form
      name="contact"
      data-netlify="true"
      method="post"
      data-netlify-honeypot="bot-field"
      action="/succes"
      className="contact-form"
    >
      <div className="fields">
        <input type="hidden" name="form-name" value="contact" />
        <div className="field">
          <label htmlFor="name" className="label">
            Nume
            <span className="help">(câmp obligatoriu)</span>
          </label>
          <div className="control">
            <input
              id="name"
              className="input"
              type="text"
              name="name"
              placeholder="ex. Ion Popescu"
              required
            />
          </div>
        </div>
        <div className="field">
          <label htmlFor="phone" className="label">
            Telefon
          </label>
          <div className="control">
            <input
              id="phone"
              className="input"
              type="text"
              name="phone"
              placeholder="ex. 0730 000 000"
              required
            />
          </div>
        </div>
        <div className="field">
          <label htmlFor="email" className="label">
            Email
            <span className="help">(câmp obligatoriu)</span>
          </label>
          <div className="control">
            <input
              id="email"
              className="input"
              type="email"
              name="email"
              placeholder="ex. ionpopescu@gmail.com"
              required
            />
          </div>
        </div>
        <div className="field">
          <label htmlFor="message" className="label">
            Mesaj
            <span className="help">(câmp obligatoriu)</span>
          </label>
          <div className="control">
            <textarea
              id="message"
              name="message"
              className="textarea"
              placeholder=""
              required
            ></textarea>{" "}
          </div>
        </div>
        <div className="field">
          <div className="control">
            <p className="help-privacy">
              Prin trimiterea acestui formular ești de acord cu{" "}
              <Link to="/privacy">politica de confidențialitate</Link>.
            </p>
            <button type="submit" className="button is-link">
              Trimite mesajul
            </button>
          </div>
        </div>
      </div>
    </form>
  </>
)

export default ContactForm
